import { useState, useEffect, useRef, useCallback } from "react";

//  VARCEL ANALYTICS
import { Analytics } from '@vercel/analytics/react';

import PostCard from "../components/PostCard";
import PostCardShimmer from "../components/PostCardShimmer";

import logo from "../../src/images/logo.png";

const DEBOUNCE_DELAY = 200; // Adjust the delay as needed

const Posts = () => {
  // const { setScrollCount, fetchLimiter } = useContext(ScholarsContext);

  const [scrollCount, setScrollCount] = useState(1);
  const [fetchLimiter, setFetchLimiter] = useState(1);

  const scrollContainerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;
    const isBottom =
      scrollContainer.scrollTop + scrollContainer.clientHeight >=
      scrollContainer.scrollHeight - 300;

    if (isBottom && fetchLimiter) {
      setScrollCount((prevCount) => prevCount + 1);
    }
  }, [setScrollCount, fetchLimiter]);

  useEffect(() => {
    let timeoutId;
    const debouncedHandleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, DEBOUNCE_DELAY);
    };
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [setScrollCount, handleScroll]);
  const [posts, setPosts] = useState([]);
  const [isGettingPosts, setIsGettingPosts] = useState(false);

  const getPosts = (pageNum) => {
    const uniqueId = localStorage.getItem("uniqueId");

    setIsGettingPosts(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://api.2016et.et/api/words?page=${pageNum}&per_page=20&token=${uniqueId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsGettingPosts(false);
        setPosts([...posts, ...result.words.data]);
        setFetchLimiter(result.words.next_page_url);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    setFetchLimiter(null);
    getPosts(scrollCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCount]);

  return (
    <div className="w-full flex justify-center">
      <div
        ref={scrollContainerRef}
        className="flex flex-col items-center w-[100%] max-w-[1600px] overflow-y-auto h-screen"
      >
        <img className="w-32 sm:40 md:w-52 py-5" src={logo} alt="logo" />
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 p-4 gap-4 sm:p-6 sm:gap-6 md:gap-8 md:p-8">
          {posts.length !== 0 ? (
            posts.map((item) => {
              return (
                <PostCard
                  key={item.id}
                  word={item.new_word}
                  meaning={item.word_meaning}
                  likeCount={item.likes_count}
                  username={item.username}
                  id={item.id}
                  isLiked={item.is_liked}
                />
              );
            })
          ) : (
            <>
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
              <PostCardShimmer />
            </>
          )}
          {(fetchLimiter || isGettingPosts) && posts.length !== 0 && (
            <>
              <PostCardShimmer />
              <PostCardShimmer />
            </>
          )}
        </div>
      </div>
      <Analytics />
    </div>
  );
};

export default Posts;
