import { useState } from "react";

import HeartFilled from "./Icons/HeartFilled";
import Heart from "./Icons/Heart";

const PostCard = ({ id, word, meaning, likeCount, username, isLiked }) => {
  const [postIsLiked, setPostIsLiked] = useState(isLiked === 1 ? true : false);
  const [postLikeCount, setPostLikeCount] = useState(Number(likeCount));

  const likePost = (id) => {
    const uniqueId = localStorage.getItem("uniqueId");
    setPostIsLiked(!postIsLiked);
    if (postIsLiked) {
      setPostLikeCount(postLikeCount - 1);
    } else {
      setPostLikeCount(postLikeCount + 1);
    }

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `https://api.2016et.et/api/like/${id}?token=${uniqueId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <div
      style={{ fontFamily: "Noto Sans Ethiopic ,sans-serif" }}
      className="bg-white rounded-md text-gray-600 flex flex-col justify-between px-5 pt-5 pb-3 space-y-2"
    >
      <div className="w-full flex flex-col space-y-4 pb-3 ">
        <div>
          <label>አዲስ ቃል:-</label>
          <h1 className="font-bold">{word}</h1>
        </div>
        <div>
          <label>ትርጉም:-</label>
          <h1 className="font-bold">{meaning}</h1>
        </div>
      </div>
      <div className="w-full flex justify-between items-center pt-2 border-t border-red-100">
        <h1 className="italic">By: {username}</h1>

        <div
          className={`bg-red-100/70  ${
            postLikeCount !== 0 ? "pl-2 pr-4 py-1.5" : "p-1.5"
          }  rounded-full flex items-center space-x-2 text-red-600`}
        >
          <button
            onClick={() => {
              likePost(id);
            }}
            className="transition-all duration-100"
          >
            {postIsLiked ? (
              <HeartFilled styles="w-6 h-6" />
            ) : (
              <Heart styles="w-6 h-6" />
            )}
          </button>

          {postLikeCount !== 0 && <h1>{postLikeCount}</h1>}
        </div>
      </div>
    </div>
  );
};

export default PostCard;
