import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Posts from "./pages/Posts";


function generateUniqueId() {
  const timestamp = Date.now();
  const uniqueId = `${timestamp}-${Math.random()
    .toString(36)
    .substr(2, 9)}-${Math.random().toString(24).substr(2, 9)}`;
  return uniqueId;
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/posts",
      element: <Posts />,
    },
  ]);

  useEffect(() => {
    // Check if the unique ID exists in local storage
    const storedUniqueId = localStorage.getItem("uniqueId");

    if (!storedUniqueId) {
      // If it doesn't exist, generate a new one and save it to local storage
      const newUniqueId = generateUniqueId();
      localStorage.setItem("uniqueId", newUniqueId);
    }
  }, []);

  return <>
  <RouterProvider router={router} />

  </>
}

export default App;
