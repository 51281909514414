import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//  VARCEL ANALYTICS
import { Analytics } from '@vercel/analytics/react';

import CustomDropdown from "../components/Dropdown";

import ConfirmationIcon from "../components/Icons/Confirmation";

import logo from "../../src/images/logo.png";
import qrImg from "../../src/images/qrcode.png";

const Home = () => {
  const [socialMediaList, setSocialMediaList] = useState([]);

  const [selectedMedia, setSelectedMedia] = useState("");
  const [newWord, setNewWord] = useState("");
  const [wordMeaning, setWordMeaning] = useState("");
  const [username, setUserName] = useState("");

  const [isSendingInfo, setIsSendingInfo] = useState(false);

  const [isWaitingConfirmation, setIsWaitingConfirmation] = useState(false);

  const handleOptionSelect = (option, dropdownId) => {
    setSelectedMedia(option);
    console.log(option, selectedMedia);
  };

  useEffect(() => {
    fetch("https://api.2016et.et/api/medias")
      .then((res) => res.json())
      .then((result) => {
        setSocialMediaList(result.medias);
        console.log(result.medias);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleNewWord = (e) => {
    setNewWord(e.target.value);
    console.log(e.target.value);
  };
  const handleWordMeaning = (e) => {
    setWordMeaning(e.target.value);
    console.log(e.target.value);
  };
  const handleUsername = (e) => {
    setUserName(e.target.value);
    console.log(e.target.value);
  };

  const registerInfo = () => {
    setIsSendingInfo(true);
    var formdata = new FormData();
    formdata.append("social_media_id", selectedMedia);
    formdata.append("new_word", newWord);
    formdata.append("word_meaning", wordMeaning);
    formdata.append("username", username);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.2016et.et/api/words", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsWaitingConfirmation(true);
        setIsSendingInfo(false);
      })
      .catch((error) => console.log("error", error));
  };

  const [wordHasError, setWordHasError] = useState(false);
  const [meaningHasError, meaningWordHasError] = useState(false);
  const [socialHasError, setSocialWordHasError] = useState(false);
  const [usernameHasError, usernameWordHasError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedMedia.length === 0) {
      setSocialWordHasError(true);
    } else {
      setSocialWordHasError(false);
    }
    if (newWord.length === 0) {
      setWordHasError(true);
    } else {
      setWordHasError(false);
    }
    if (wordMeaning.length === 0) {
      meaningWordHasError(true);
    } else {
      meaningWordHasError(false);
    }
    if (username.length === 0) {
      usernameWordHasError(true);
    } else {
      usernameWordHasError(false);
    }

    console.log(
      selectedMedia.length === 0,
      newWord.length === 0,
      wordMeaning.length === 0,
      username.length === 0,
      selectedMedia.length
    );

    if (
      selectedMedia.length !== 0 &&
      newWord.length !== 0 &&
      wordMeaning.length !== 0 &&
      username.length !== 0
    ) {
      registerInfo();
    }
  };

  const errStyle = "border-red-300";
  const normalStyle = "border-blue-100";

  return (
    <div
      style={{ fontFamily: "Noto Sans Ethiopic ,sans-serif" }}
      className="min-h-screen w-full  flex flex-col items-center"
    >
      <img className="w-32 sm:40 md:w-52 py-5" src={logo} alt="logo" />
      {isWaitingConfirmation && (
        <div className="w-[90%] p-5 max-w-[500px] flex flex-col items-center space-y-3  bg-slate-50 rounded-md text-center text-gray-700">
          <ConfirmationIcon styles="text-green-500 w-16 h-16" />
          <h1 className="font-bold text-2xl">Sent Successfully!</h1>
          <div className="w-[80%]">
            <p>
              We have received your word and we will post it as soon as it is
              approved!
            </p>
          </div>
          <Link
            to="/posts"
            onClick={() => {
              setIsWaitingConfirmation(false);
            }}
            className="bg-red-600 text-white px-5 py-2 rounded"
          >
            Show Posts
          </Link>
        </div>
      )}
      {!isWaitingConfirmation && (
        <div className="w-[90%] max-w-[500px] flex flex-col  bg-slate-50 rounded-md">
          <div className="text-center p-3 text-2xl font-bold text-red-600">
            <h1>አዲስ ቃልን ያስመዝግቡ</h1>
          </div>
          <hr className="border-t border-red-200 w-full" />
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center space-y-5 p-4  text-gray-600"
          >
            <div className="flex flex-col w-full space-y-2">
              <h1 className="font-bold">አዲስ ቃል</h1>
              <input
                onChange={handleNewWord}
                className={`border ${
                  wordHasError ? errStyle : normalStyle
                } h-[45px] flex items-center rounded px-2.5 outline-none`}
                type="text"
              ></input>
            </div>

            <div className="flex flex-col w-full space-y-2">
              <h1 className="font-bold">ትርጉም</h1>
              <textarea
                onChange={handleWordMeaning}
                rows="3"
                name="Message"
                placeholder="ትርጉም"
                className={`border ${
                  meaningHasError ? errStyle : normalStyle
                } outline-none p-2.5 rounded`}
              />
            </div>
            <div className="flex flex-col sm:flex-row w-full  space-y-5 sm:space-y-0 sm:space-x-4">
              <div className="flex flex-col w-full space-y-2">
                <h1 className="font-bold">Select Social Media</h1>
                <CustomDropdown
                  options={socialMediaList}
                  defaultValue={"Select"}
                  onSelect={handleOptionSelect}
                  dropdownId={1}
                  isSelected={socialHasError}
                />
              </div>
              <div className="flex flex-col w-full space-y-2">
                <h1 className="font-bold">Username</h1>
                <input
                  onChange={handleUsername}
                  className={`border ${
                    usernameHasError ? errStyle : normalStyle
                  } h-[45px] flex items-center rounded px-2.5 outline-none`}
                  type="text"
                ></input>
              </div>
            </div>
            <div>
              <button
                className="w-[170px]  bg-red-600 py-2.5 rounded-full text-white text-xl relative overflow-hidden font-bold sm:mt-4"
                type="submit"
              >
                {isSendingInfo && (
                  <div className="absolute top-0 left-0 bg-black/30 w-full h-full flex items-center justify-center">
                    <div className="w-8 h-8 rounded-full border-t border-t-white animate-spin"></div>
                  </div>
                )}
                ያስመዝግቡ
              </button>
            </div>
          </form>
        </div>
      )}
      <img
        src={qrImg}
        alt="QR code"
        className="w-28 lg:w-32 my-8 lg:my-0 lg:absolute bottom-10 left-16 xl:left-24 xl:bottom-16"
      />
      <Analytics />
    </div>
  );
};

export default Home;
