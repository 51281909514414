import "../../src/shimmer.css";
const PostCardShimmer = () => {
  return (
    <div
      style={{ fontFamily: "Noto Sans Ethiopic ,sans-serif" }}
      className="bg-white rounded-md text-gray-600 flex flex-col justify-between px-5 pt-5 pb-3 space-y-2"
    >
      <div className="w-full flex flex-col space-y-4 pb-3 ">
        <div>
          <label>አዲስ ቃል:-</label>
          <div className="w-[70%] h-5 rounded-full animate-pulse-gradient"></div>
        </div>
        <div className="flex flex-col space-y-2">
          <label>ትርጉም:-</label>
          <div className="w-full h-3 rounded-full animate-pulse-gradient"></div>
          <div className="w-full h-3 rounded-full animate-pulse-gradient"></div>
          <div className="w-2/3 h-3 rounded-full animate-pulse-gradient"></div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center pt-2 border-t border-red-100">
        <div className="w-[100px] h-5 rounded-full animate-pulse-gradient"></div>

        <div className="w-[100px] h-9 rounded-full animate-pulse-gradient"></div>
      </div>
    </div>
  );
};

export default PostCardShimmer;
